export const FormularioSesion = [{
    type: "text",
    label: "Usuario",
    name: "usuario",
    required: true
},
{
    type: "password",
    label: "Contraseña",
    name: "contrasenia",
    required: true
}]